<template>
    <div class="callSetting">
        <div class="hidePhoneNumber">
            <div class="hidePhoneNumber-title">号码隐藏规则</div>
            <el-card>
                <div
                    class="hidePhoneNumber-text"
                >查看线索、客户的手机或电话时（包括通话记录等所显示客户手机号的页面），号码统一脱敏显示（例如：155****2589）</div>
                <div class="hidePhoneNumber-btn">
                    号码隐藏规则设置：
                    <el-switch
                        v-model="hidePhoneNumber"
                        :active-value="1"
                        :inactive-value="0"
                        @change="hidePhoneNumberChange"
                    />
                </div>
            </el-card>
        </div>

        <div class="answer-default">
            <div class="answer-default-title">来电话号码接听规则</div>
            <el-card>
                <div class="hidePhoneNumber-text">指定一个员工账号来接听公共池线索和公海客户以及未知号码的来电</div>
                <div class="add-staff" @click="showAddStaff">
                    <img
                        src="@/assets/common/add-staff.png"
                        alt
                        style="width: 30px;height: 26px; transform: translate(10px,5px);"
                    />
                    <span>添加员工</span>
                </div>
                <div class="staff-list">
                    <el-tag
                        v-if="defaultSeatName"
                        closable
                        @close="clearStaff()"
                    >{{ defaultSeatName }}</el-tag>
                </div>
            </el-card>
        </div>

        <div class="callRules">
            <div class="callRules-title">呼叫风控规则</div>
            <el-card>
                <div class="callRules-CallInterval">
                    <span id="required">*</span>一天内同一公海号码呼叫时间间隔：
                    <el-input
                        v-model="callInterval"
                        style="width: 170px"
                        size="small"
                        oninput="value=value.replace(/[^\d]/g,'')"
                        @change="inputSafeInterception('callInterval', 24)"
                    >
                        <template slot="append">小时</template>
                    </el-input>
                </div>
                <div class="callRules-callOnline">
                    <span id="required">*</span>一天内同一公海号码轮呼次数上限：
                    <el-input
                        v-model="callOnline"
                        style="width: 170px"
                        size="small"
                        oninput="value=value.replace(/[^\d]/g,'')"
                        @change="inputSafeInterception('callOnline', 10)"
                    >
                        <template slot="append">次</template>
                    </el-input>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="仅对公海中的线索及客户生效，最大上限为10次"
                        placement="right"
                    >
                        <img
                            src="@/assets/setting/tipIcon.png"
                            alt
                            style="width: 20px;height: 20px; transform: translate(10px,5px);"
                        />
                    </el-tooltip>
                </div>
                <div class="callRules-maxNumber">
                    <span id="required">*</span>一天内同一被叫号码呼叫次数上限：
                    <el-input
                        v-model="maxNumber"
                        style="width: 170px"
                        size="small"
                        oninput="value=value.replace(/[^\d]/g,'')"
                        @change="inputSafeInterception('maxNumber', 10)"
                    >
                        <template slot="append">次</template>
                    </el-input>
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="对非公海中的线索及客户生效，最大上限为10次"
                        placement="right"
                    >
                        <img
                            src="@/assets/setting/tipIcon.png"
                            alt
                            style="width: 20px;height: 20px; transform: translate(10px,5px);"
                        />
                    </el-tooltip>
                </div>
            </el-card>
        </div>
        <assign-dialog
            title="选择员工"
            :show-dialog.sync="isShowChooseStaffDialog"
            @submitPartment="submitPartment"
        />
    </div>
</template>

<script>
import AssignDialog from '@/views/client/components/assignDialog';
import { getCallSetting, reviseCallSetting } from '@/api/callSetting.js';
export default {
    data() {
        return {
            // 隐藏开关当前的value
            hidePhoneNumber: false,
            // 一天内同一公海号码呼叫时间间隔
            callInterval: 3,
            // 一天内同一公海号码轮呼次数上限
            callOnline: 3,
            // 一天内同一被叫号码呼叫次数上限
            maxNumber: 3,
            // 默认员工id
            defaultSeatStaffId: '',
            // 默认员工名称
            defaultSeatName: '',
            // 显示选择员工
            isShowChooseStaffDialog: false,
        };
    },
    methods: {
        // 隐藏开关发生变化
        hidePhoneNumberChange() {
            this.reviseCallSettingData();
        },
        // input安全判断
        inputSafeInterception(data, numMax) {
            const val = this[data] * 1;
            if (1 > val || val > numMax) {
                this[data] = 3;
                this.$message({
                    message: '值必须在1-' + numMax + '之间',
                    type: 'warning',
                });
            } else {
                this.reviseCallSettingData();
            }
        },
        getCallSettingData() {
            // 获取呼叫设置方法
            getCallSetting().then((res) => {
                if (res.code !== '0')
                    return this.$message.error('获取呼叫设置失败');
                const {
                    phoneNumberHideEnabled,
                    publicPhoneBatchCallInterval,
                    publicPhoneDailyBatchCallMax,
                    privatePhoneDailyCallMax,
                    defaultSeatName,
                    defaultSeatStaffId,
                } = res.data;
                this.hidePhoneNumber = phoneNumberHideEnabled;
                this.callInterval = publicPhoneBatchCallInterval;
                this.callOnline = publicPhoneDailyBatchCallMax;
                this.maxNumber = privatePhoneDailyCallMax;
                this.defaultSeatName = defaultSeatName;
                this.defaultSeatStaffId = defaultSeatStaffId;
            });
        },
        // 修改呼叫设置方法
        reviseCallSettingData() {
            reviseCallSetting({
                phoneNumberHideEnabled: this.hidePhoneNumber,
                publicPhoneBatchCallInterval: this.callInterval,
                publicPhoneDailyBatchCallMax: this.callOnline,
                privatePhoneDailyCallMax: this.maxNumber,
                defaultSeatStaffId: this.defaultSeatStaffId,
            }).then((res) => {
                if (res.code !== '0')
                    return this.$message.error('修改呼叫设置失败');
                this.defaultSeatName = res.data.defaultSeatName;
            });
        },
        // 删除默认员工
        clearStaff(id) {
            this.defaultSeatName = '';
            this.defaultSeatStaffId = '';
            this.reviseCallSettingData();
        },
        showAddStaff() {
            this.isShowChooseStaffDialog = true;
        },
        // 添加默认员工
        submitPartment(data) {
            this.defaultSeatStaffId = data;
            this.reviseCallSettingData();
        },
    },
    components: {
        AssignDialog,
    },
    created() {
        // 调用获取呼叫设置方法
        this.getCallSettingData();
    },
};
</script>

<style lang="less" scoped>
.callSetting {
    padding: 20px 10px 0;
}
// 隐藏手机号相关
.hidePhoneNumber {
    padding-bottom: 26px;
    .hidePhoneNumber-title {
        padding-bottom: 10px;
    }
    .hidePhoneNumber-text {
        padding-bottom: 14px;
        font-size: 14px;
    }
    .hidePhoneNumber-btn {
        font-size: 14px;
        text-align: center;
    }
}

// 默认接听
.answer-default {
    padding-bottom: 26px;
    .answer-default-title {
        padding-bottom: 20px;
    }
    .add-staff {
        padding: 20px 0px;
        cursor: pointer;
        // 禁止选中
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Chrome/Safari/Opera */
        -khtml-user-select: none; /* Konqueror */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currentlynot supported by any browser */
        img {
            vertical-align: text-bottom;
        }
        span {
            padding-left: 10px;
        }
    }
    .staff-list {
        .el-tag {
            margin-right: 20px;
        }
    }
}

// 通话规则相关
.callRules {
    text-align: center;
    .callRules-title {
        padding-bottom: 10px;
        text-align: left;
    }
    .callRules-CallInterval {
        transform: translateX(-10px);
    }
    .callRules-callOnline {
        padding: 24px 0;
    }
    #required {
        color: red;
        transform: translateY();
    }
}
</style>
